.image_carrousel {
    width: 100%;
    height: calc(100vh - 75.45px);
    filter: brightness(0.40);
    object-fit: cover;
}

.slide_container {
    position: relative;
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.overlay h2 {
    font-size: 4rem;
}