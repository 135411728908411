.loader_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.loader{
    width: auto;
    height: 100px;
}