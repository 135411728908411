.header_dialog {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    h1 {
        font-size: 14px;
        font-weight: 700;
    }

    a {
        width: 12rem;
        button {
            border: none;
            margin: 0.7rem 0;
            padding: 5px 10px;
            background-color: var(--firstColor);
            color: white;
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            width: 100%;
        }

        button:hover {
            background-color: var(--hoverColor);
        }
    }
}

.content_qr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    img {
        width: auto;
        height: 350px;
    }
}