.edit_in_place_textarea {
    border: none;
    width: 80%;
    background: transparent;
    resize: none;
    height: 220px;
    padding: 5px;
    margin-bottom: 10px;
    min-height: auto;
}

.btn_container {
    width: 80%;
    margin-top: 2rem ;
}