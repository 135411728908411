.form_cad_content {
    border: none;
    box-shadow: none;
}

.btn_remove_file {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.btn_adc_file {
    margin: 0;
}