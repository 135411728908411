.container_card{
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 3rem 5rem;
    min-height: auto;
}

.container_card section img{
    width: 100%;
    height: 200px;
    border-radius: 4px;
}

.container_card .container_content{
    padding: 0 0.6rem;
    min-height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container_card .container_content h1{
    display: flex;
    font-size: 20px;
    color: var(--firstColor);
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    text-transform: uppercase;
}

.container_card .container_content h4{
    margin: 1rem 0;
    text-align: center;
}

.container_card .container_btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.container_card .container_btn a{
    display: flex;
    width: 50%;
    color: white;
    text-transform: uppercase;
    background-color: var(--firstColor);
    justify-content: center;
    align-items: center;
    padding: 0.6rem 1.8rem;
    border-radius: 5px;
    border: 2px solid var(--firstColor);
    transition: .4s;
    font-weight: bold;
}

.container_card .container_btn a:hover{
    color: var(--firstColor);
    background-color: transparent;
}

@media screen and (max-width: 720px) {
    .container_card{
        margin: 3rem 3rem;
    }
}