.form_cansau{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.form_cansau section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form_cansau section img{
    width: auto;
    height: 100px;
}

.form_cansau section h1{
    color: var(--firstColor);
    font-size: 2rem;
    margin-top: 1rem;
}

.form_cansau section h4{
    width: 80%;
    color: black;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.group section label,
.group section input,
.group section textarea{
    width: 80%;
}

.group section label{
    color: var(--firstColor);
    font-weight: bold;
    margin-bottom: 0.6rem;    
}

.group section input,
.group section textarea{
    padding: 0.6rem;
    border: none;
    border: 2px solid var(--firstColor);
    border-radius: 5px;
    margin-bottom: 1rem;
}

.group section input::placeholder,
.group section textarea::placeholder{
    color: black;
    font-weight: bold;
}

.group button{
    padding: 0.6rem 8rem;
    cursor: pointer;
    margin: 1rem 0;
    border: none;
    background-color: var(--firstColor);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid var(--firstColor);
    border-radius: 5px;
    transition: .4s;
}

.group button:hover{
    color: var(--firstColor);
    background-color: transparent;
}

