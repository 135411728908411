.menu {
    justify-content: end;
}

.herder_menu {
    background-color: var(--secondColor) !important;
    color: var(--firstColor) !important;
}

.logo {
    width: auto;
    height: 45px;
    margin-right: 15px;
}

.btn {
    color: var(--firstColor) !important;
}

.btn button {
    color: var(--firstColor) !important;
}

@media screen and (max-width: 900px) {
    
    .logo {
        display: none;
    }
}