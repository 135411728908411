.cardsContainer {
    display: flex;
    flex-direction: column;
}

.card {
    display: flex;
    padding: 40px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.card:nth-child(odd) {
    background-color: white;
}

.card:nth-child(even) {
    background-color: #fafafa;
}

.card h1 {
    color: var(--firstColor);
    margin-bottom: 1rem;
    font-size: 20px;
    text-transform: uppercase;
    width: 80%;
    text-align: center;
}

.card h3 {
    text-align: justify;
    width: 80%;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
}

.card img {
    width: auto;
    height: 100px;
}

.buttonContainer {
    width: 80%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.buttonContainer button {
    background-color: var(--firstColor);
    gap: 5px;
}

.content_btn_open_cad_conteudo {
    width: 80%;
    margin: 0 auto;
    padding: 0 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
}

iframe {
    margin-top: 1rem;
}

.dotMenuContainer {
    position: absolute;
    top: 10px;
    right: 50px;
}

@media screen and (max-width: 800px) {
    .card h3 {
        width: 90%;
    }
}

@media screen and (max-width: 720px) {
    iframe {
        width: 275px;
        height: 150px;
    }
}
