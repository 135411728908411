.message{
    width: 80%;
    padding: 1em;
    border: 1px solid #000;
    margin: 0 auto;
    margin-bottom: 2em;
    margin-top: 2rem;
    border-radius: 5px;
}

.success{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    display: flex;
    align-items: center;
}

.error{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    display: flex;
    align-items: center;
}

.inform{
    color: #bbad33;
    background-color: #fffd74;
    border-color: #fffc68;
    display: flex;
    align-items: center;
}