.sidenav{
    width: 280px;
    height: 4rem;
    background-color: var(--secondColor);
    position: fixed;
    padding: 30px;
    right: -100%;
    height: 100%;
    transition: all .5s;
}

.sidenav.active{
    right: 0;
    z-index: 100;
}

.sidenav ul{
    padding: 0;
}

.sidenav ul li{
    list-style: none;
    margin: 1rem 0;
}

.sidenav ul li a{
    color: white;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 10px 15px;
    border-radius: 6px;
    transition: .4s;
    color: #024695;
    font-weight: bold;
}

.logo{
    width: 40px;
    margin-top: 2rem;
}

.sidenav ul li a:hover,
.sidenav ul li a:active{
    background: var(--hoverColor);
    color: white;
}

.sidenav ul li a i{
    margin-right: 1rem;
}

.submit_group{
    max-height: 0;
    overflow: hidden;
    margin-left: 2rem;
}

.show {
    max-height: 100%;
}

.sidenav ul li .submit_group a{
    font-size: 14px;
}
