@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
}

:root{
  --firstColor: #024695;
  --secondColor: #efefef;
  --hoverColor: #61a8d4;
}

body,
html{
  overflow-x: hidden;
}

a{
  text-decoration: none;
}

.primary-btn{
  background-color: var(--firstColor) !important;
  color: white !important;
}

.secondary-btn{
  color: var(--firstColor) !important;
}

.header-app{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: var(--secondColor);
  height: 40px;
}

.header-app section{
  display: flex;
  align-items: center;
  height: 100%;
}

.header-app section img{
  width: auto;
  height: 50px;
  margin-right: 1rem;
}

.header-app section h1{
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--firstColor);
}

.header-app section i{
  font-size: 3rem;
  color: var(--firstColor);
}

.header-app section svg{
  color: var(--firstColor);
  font-size: 1.5rem;
  cursor: pointer;
}

form {
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 4px;
}

.form-row {
  display: flex;
  margin: 32px 0;
}

.form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}

.MuiOutlinedInput-notchedOutline span {
  padding-left: 15px !important;
}

.MuiPaper-elevation.MuiDialog-paper {
  width: 80% !important;
  max-width: none !important;
}

.MuiTextField-root {
  width: 100%;
}