.container_login {
    display: flex;
}

.container_login .left {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.container_login .left img {
    width: auto;
    height: 600px;
}

.container_login .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.container_login .right img {
    width: auto;
    height: 100px;
}

.form_login {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    width: 400px;
    padding: 3rem 2rem;
}

.input_data {
    width: 100%;
}

.button_save a {
    color: white !important;
    text-transform: uppercase;
}

.button_save {
    margin: 20px 0 !important;
    background-color: var(--firstColor) !important;
}

.input_data {
    margin-top: 2rem;
}