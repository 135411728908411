footer {
    background-color: var(--secondColor);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 90%;
}

.baseboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--firstColor);
    font-size: 14px;
    border-top: solid 1px var(--firstColor);
    padding: 20px 0;
}

.baseboard .copy_rights p{
    display: flex;
    align-items: center;
}

.baseboard .copy_rights p span{
    margin-left: 5px;
}

.baseboard .dev p span{
    font-weight: bold;
    text-transform: uppercase;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    flex-wrap: wrap;
}

.content div {
    width: 25%;
}

.content div:first-child {
    display: flex;
    align-items: center;
}

.content .item_footer {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
        color: var(--firstColor);
    }

    span, a {
        display: flex;
        align-items: center;
        color: var(--firstColor);
        gap: 10px;
        font-size: 14px;
    }
}

.content .social_media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;

    a {
        color: var(--firstColor);
        display: flex;
        align-items: center;
        cursor: pointer;
        
        svg {
            width: auto;
            height: 32px;
        }
    }
}

@media screen and (max-width: 1070px) {
    .content {
        flex-direction: column;
        gap: 40px
    }

    .content div {
        width: 100%;
    }

    .content .social_media {
        padding-left: 0;
    }
}